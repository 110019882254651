<template>
<markdown pagePath="src/views/protected/backend/staging/views/sample.md" :slot-key="$parent.slotKey"><p>Hello World !</p>
<p>Local Data: {{ localData }}</p>
<p>Frontmatter: {{ $options.$frontmatter }}</p>
<h2 id="use-vue-component-and-html"><a class="header-anchor" href="#use-vue-component-and-html">#</a> Use Vue Component and HTML</h2>
<p>Vue Component: <why-use /></p>
<div id="test_content">
<h3 id="global-component"><a class="header-anchor" href="#global-component">#</a> Global Component</h3>
  <Contact />
</div>
<h1 id="heading-1"><a class="header-anchor" href="#heading-1">#</a> Heading 1</h1>
<h2 id="heading-2"><a class="header-anchor" href="#heading-2">#</a> Heading 2</h2>
<h3 id="heading-3"><a class="header-anchor" href="#heading-3">#</a> Heading 3</h3>
<h4 id="heading-4"><a class="header-anchor" href="#heading-4">#</a> Heading 4</h4>
<h5 id="heading-5"><a class="header-anchor" href="#heading-5">#</a> Heading 5</h5>
<h6 id="heading-6"><a class="header-anchor" href="#heading-6">#</a> Heading 6</h6>
<h2 id="paragraph"><a class="header-anchor" href="#paragraph">#</a> Paragraph</h2>
<p>Lorem ipsum dolor sit amet, Internal test link - <a href="/about">About</a> consectetur adipiscing elit. <strong>Strong text</strong> pellentesque ligula commodo viverra vehicula. <em>Italic text</em> at ullamcorper enim. Morbi a euismod nibh. <u>Underline text</u> non elit nisl. <s>Deleted text</s> tristique, sem id condimentum tempus, metus lectus venenatis mauris, sit amet semper lorem felis a eros. Fusce egestas nibh at sagittis auctor. Sed ultricies ac arcu quis molestie. Donec dapibus nunc in nibh egestas, vitae volutpat sem iaculis. <a href="https://staging.wakaru.org" target="_blank" rel="noopener noreferrer">External test link<OutboundLink/></a>Curabitur sem tellus, elementum nec quam id, fermentum laoreet mi. Ut mollis ullamcorper turpis, vitae facilisis velit ultricies sit amet. Etiam laoreet dui odio, id tempus justo tincidunt id. Phasellus scelerisque nunc sed nunc ultricies accumsan.</p>
<p>Interdum et malesuada fames ac ante ipsum primis in faucibus. <code>Sed erat diam</code>, blandit eget felis aliquam, rhoncus varius urna. Donec tellus sapien, sodales eget ante vitae, feugiat ullamcorper urna. Praesent auctor dui vitae dapibus eleifend. Proin viverra mollis neque, ut ullamcorper elit posuere eget.</p>
<h2 id="list-types"><a class="header-anchor" href="#list-types">#</a> List Types</h2>
<h3 id="definition-list-dl"><a class="header-anchor" href="#definition-list-dl">#</a> Definition List (dl)</h3>
<dl><dt>Definition List Title</dt><dd>This is a definition list division.</dd></dl>
<h3 id="ordered-list-ol"><a class="header-anchor" href="#ordered-list-ol">#</a> Ordered List (ol)</h3>
<ol>
<li>List Item 1</li>
<li>List Item 2</li>
<li>List Item 3</li>
</ol>
<h3 id="unordered-list-ul"><a class="header-anchor" href="#unordered-list-ul">#</a> Unordered List (ul)</h3>
<ul>
<li>List Item 1</li>
<li>List Item 2</li>
<li>List Item 3</li>
</ul>
<h2 id="table"><a class="header-anchor" href="#table">#</a> Table</h2>
<table>
<thead>
<tr>
<th>Header 1</th>
<th>Header 2</th>
<th>Header 3</th>
</tr>
</thead>
<tbody>
<tr>
<td>Division 1</td>
<td>Division 2</td>
<td>Division 3</td>
</tr>
<tr>
<td>Division 1</td>
<td>Division 2</td>
<td>Division 3</td>
</tr>
<tr>
<td>Division 1</td>
<td>Division 2</td>
<td>Division 3</td>
</tr>
<tr>
<td>Division 1</td>
<td>Division 2</td>
<td>Division 3</td>
</tr>
</tbody>
</table>
<h2 id="misc-stuff-abbr-acronym-sub-sup-etc"><a class="header-anchor" href="#misc-stuff-abbr-acronym-sub-sup-etc">#</a> Misc Stuff - abbr, acronym, sub, sup, etc</h2>
<p>Lorem <sup>superscript</sup> dolor <sub>subscript</sub> amet, consectetuer adipiscing <kbd>ctrl + c</kbd>. Nullam dignissim convallis est. Quisque aliquam. <cite>cite</cite>. Nunc iaculis suscipit dui.
Nam
sit amet sem. Aliquam libero nisi, imperdiet at, tincidunt nec, gravida vehicula, nisl. Praesent mattis, massa quis luctus fermentum, turpis mi volutpat justo, eu volutpat enim diam eget metus. Maecenas ornare tortor. Donec sed tellus eget sapien fringilla nonummy. <acronym title="National Basketball Association">NBA</acronym> Mauris a ante. Suspendisse quam sem, consequat at, commodo vitae, feugiat in, nunc. Morbi imperdiet augue quis tellus.  <abbr title="Avenue">AVE</abbr></p>
<h2 id="horizontal-rules"><a class="header-anchor" href="#horizontal-rules">#</a> Horizontal Rules</h2>
<hr>
<h2 id="typographic-replacements"><a class="header-anchor" href="#typographic-replacements">#</a> Typographic replacements</h2>
<p>Enable typographer option to see result.</p>
<p>(c) (C) (r) (R) (tm) (TM) (p) (P) +-</p>
<p>test.. test... test..... test?..... test!....</p>
<p>!!!!!! ???? ,,  -- ---</p>
<p>&quot;Smartypants, double quotes&quot; and 'single quotes'</p>
<h2 id="emphasis"><a class="header-anchor" href="#emphasis">#</a> Emphasis</h2>
<p><strong>This is bold text</strong></p>
<h2 id="blockquotes"><a class="header-anchor" href="#blockquotes">#</a> Blockquotes</h2>
<blockquote>
<p>Blockquotes can also be nested...</p>
<blockquote>
<p>...by using additional greater-than signs right next to each other...</p>
<blockquote>
<p>...or with spaces between arrows.</p>
</blockquote>
</blockquote>
</blockquote>
<h2 id="code"><a class="header-anchor" href="#code">#</a> Code</h2>
<p>Inline <code>code</code></p>
<p>Syntax highlighting</p>
<!--beforebegin--><div class="language-js extra-class"><!--afterbegin--><pre v-pre class="language-js"><code><span class="token keyword">var</span> <span class="token function-variable function">foo</span> <span class="token operator">=</span> <span class="token keyword">function</span> <span class="token punctuation">(</span><span class="token parameter">bar</span><span class="token punctuation">)</span> <span class="token punctuation">{</span>
  <span class="token keyword">return</span> bar<span class="token operator">++</span><span class="token punctuation">;</span>
<span class="token punctuation">}</span><span class="token punctuation">;</span>

console<span class="token punctuation">.</span><span class="token function">log</span><span class="token punctuation">(</span><span class="token function">foo</span><span class="token punctuation">(</span><span class="token number">5</span><span class="token punctuation">)</span><span class="token punctuation">)</span><span class="token punctuation">;</span>
</code></pre>
<!--beforeend--></div><!--afterend--><h2 id="tables"><a class="header-anchor" href="#tables">#</a> Tables</h2>
<table>
<thead>
<tr>
<th>Option</th>
<th>Description</th>
</tr>
</thead>
<tbody>
<tr>
<td>data</td>
<td>path to data files to supply the data that will be passed into templates.</td>
</tr>
<tr>
<td>engine</td>
<td>engine to be used for processing templates. Handlebars is the default.</td>
</tr>
<tr>
<td>ext</td>
<td>extension to be used for dest files.</td>
</tr>
</tbody>
</table>
<p>Right aligned columns</p>
<table>
<thead>
<tr>
<th style="text-align:right">Option</th>
<th style="text-align:right">Description</th>
</tr>
</thead>
<tbody>
<tr>
<td style="text-align:right">data</td>
<td style="text-align:right">path to data files to supply the data that will be passed into templates.</td>
</tr>
<tr>
<td style="text-align:right">engine</td>
<td style="text-align:right">engine to be used for processing templates. Handlebars is the default.</td>
</tr>
<tr>
<td style="text-align:right">ext</td>
<td style="text-align:right">extension to be used for dest files.</td>
</tr>
</tbody>
</table>
<h2 id="links"><a class="header-anchor" href="#links">#</a> Links</h2>
<p><a href="http://dev.nodeca.com" target="_blank" rel="noopener noreferrer">link text<OutboundLink/></a></p>
<p><a href="http://nodeca.github.io/pica/demo/" title="title text!" target="_blank" rel="noopener noreferrer">link with title<OutboundLink/></a></p>
<h2 id="images"><a class="header-anchor" href="#images">#</a> Images</h2>
<p><img src="https://picsum.photos/id/237/200/300" alt="Dog"></p>
<p><img src="https://picsum.photos/200" alt="RandomImage" title="Random Image"></p>
<p>With a reference later in the document defining the URL location:</p>
<h2 id="plugins"><a class="header-anchor" href="#plugins">#</a> Plugins</h2>
<p>The killer feature of <code>markdown-it</code> is very effective support of
<a href="https://www.npmjs.org/browse/keyword/markdown-it-plugin" target="_blank" rel="noopener noreferrer">syntax plugins<OutboundLink/></a>.</p>
<h3 id="emojies"><a class="header-anchor" href="#emojies">#</a> <a href="https://github.com/markdown-it/markdown-it-emoji" target="_blank" rel="noopener noreferrer">Emojies<OutboundLink/></a></h3>
<blockquote>
<p>Classic markup: 😉 :crush: 😢 :tear: 😆 😋</p>
<p>Shortcuts (emoticons): 😃 😦 😎 😉</p>
</blockquote>
<p>see <a href="https://github.com/markdown-it/markdown-it-emoji#change-output" target="_blank" rel="noopener noreferrer">how to change output<OutboundLink/></a> with twemoji.</p>
<h3 id="subscript-superscript"><a class="header-anchor" href="#subscript-superscript">#</a> <a href="https://github.com/markdown-it/markdown-it-sub" target="_blank" rel="noopener noreferrer">Subscript<OutboundLink/></a> / <a href="https://github.com/markdown-it/markdown-it-sup" target="_blank" rel="noopener noreferrer">Superscript<OutboundLink/></a></h3>
<ul>
<li>19^th^</li>
<li>H~2~O</li>
</ul>
<h3 id="ins"><a class="header-anchor" href="#ins">#</a> <a href="https://github.com/markdown-it/markdown-it-ins" target="_blank" rel="noopener noreferrer">&lt;ins&gt;<OutboundLink/></a></h3>
<p>++Inserted text++</p>
<h3 id="mark"><a class="header-anchor" href="#mark">#</a> <a href="https://github.com/markdown-it/markdown-it-mark" target="_blank" rel="noopener noreferrer">&lt;mark&gt;<OutboundLink/></a></h3>
<p>==Marked text==</p>
<h3 id="abbreviations"><a class="header-anchor" href="#abbreviations">#</a> <a href="https://github.com/markdown-it/markdown-it-abbr" target="_blank" rel="noopener noreferrer">Abbreviations<OutboundLink/></a></h3>
<p>This is HTML abbreviation example.</p>
<p>It converts &quot;HTML&quot;, but keep intact partial entries like &quot;xxxHTMLyyy&quot; and so on.</p>
<p>[HTML][]: Hyper Text Markup Language</p>
<h3 id="custom-containers"><a class="header-anchor" href="#custom-containers">#</a> <a href="https://github.com/markdown-it/markdown-it-container" target="_blank" rel="noopener noreferrer">Custom containers<OutboundLink/></a></h3>
<p>::: warning
<em>here be dragons</em>
:::</p>
</markdown>
</template>
<script>

export default {
  data() {
    return {
      localData: 'Data defined inside <script> of md file.',
    };
  },
};

</script>

<style lang="stylus">

#test_content
  width 100%
  padding 2rem

</style>

<frontmatter>{"layout":"@/components/layout/MarkdownView.vue","title":"Sample Markdown","categories":["CMS"],"tags":["Netlify","CMS"],"comments":true,"author":"TestUser","coverimage":"/img/icons/android-chrome-512x512.png","abstract":"Short explanation of the content of this markdown file.","date":"2019-04-17T21:06:28.195Z"}</frontmatter>
